import { createSlice } from "@reduxjs/toolkit";

const initialHashtagsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  hashtagsList: null,
  entities: null,
  linked: null,
  hashtagForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const secondaryHashtagsSlice = createSlice({
  name: "secondaryHashtags",
  initialState: initialHashtagsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getHashtagById
    linkedFetched: (state, action) => {
      state.listLoading = false;
      state.linked = action.payload.entities;
      state.error = null;
    },
    // getHashtagById
    hashtagFetched: (state, action) => {
      state.actionsLoading = false;
      state.hashtagForEdit = action.payload.hashtagForEdit;
      state.error = null;
    },
    // findHashtags
    hashtagsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findHashtagsList
    hashtagsListFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.hashtagsList = result;
    },
    // createHashtag
    hashtagCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.hashtag);
    },
    // updateHashtag
    hashtagUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.hashtag.id) {
      //     return action.payload.hashtag;
      //   }
      //   return entity;
      // });
    },
    hashtagDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    hashtagsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    hashtagRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    hashtagsRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
    hashtagBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = false;
    },
    hashtagsBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = false;
        }
      });
    },
    hashtagUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = true;
    },
    hashtagsUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = true;
        }
      });
    },
    hashtagsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    hashtagPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isPublished = true;
    },
    hashtagUnpublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isPublished = false;
    },
    hashtagsPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isPublished = true;
        }
      });
    },
    hashtagsUnpublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isPublished = false;
        }
      });
    },
  },
});
