export default {
  superAdministrator: [
    {
      _id: "oasidguierjio1",
      section: "Users Manager",
      list: false,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio2",
      section: "Comments Manager",
      list: true,
      view: true,
      add: false,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio3",
      section: "Stories Manager",
      list: true,
      view: true,
      add: false,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio35",
      section: "Content Publish Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio4",
      section: "Contents Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio5",
      section: "Polls Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: false,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio6",
      section: "Hashtags Manager",
      list: true,
      view: false,
      add: false,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio7",
      section: "Categories Manager",
      list: true,
      view: false,
      add: true,
      edit: false,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio17",
      section: "SubCategories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio18",
      section: "Breaking Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio8",
      section: "Series Manager",
      list: false,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio9",
      section: "Search Manager",
      list: false,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio10",
      section: "Gallery Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio11",
      section: "Column Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio12",
      section: "Quiz Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio13",
      section: "Question Bank Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio14",
      section: "CMS User Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio15",
      section: "Feedback Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio16",
      section: "Role Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
  ],
  administrator: [
    {
      _id: "oasidguierjio1",
      section: "Users Manager",
      list: true,
      view: true,
      add: false,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio2",
      section: "Comments Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio3",
      section: "Stories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio35",
      section: "Content Publish Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio4",
      section: "Contents Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio18",
      section: "Breaking Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio5",
      section: "Polls Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio6",
      section: "Hashtags Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio7",
      section: "Categories Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio17",
      section: "SubCategories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio8",
      section: "Series Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio9",
      section: "Search Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio10",
      section: "Gallery Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio11",
      section: "Column Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio12",
      section: "Quiz Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio13",
      section: "Question Bank Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio14",
      section: "CMS User Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio15",
      section: "Feedback Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio16",
      section: "Role Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
  ],
  editor: [
    {
      _id: "oasidguierjio1",
      section: "Users Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio2",
      section: "Comments Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio3",
      section: "Stories Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio35",
      section: "Content Publish Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio4",
      section: "Contents Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio18",
      section: "Breaking Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio5",
      section: "Polls Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio6",
      section: "Hashtags Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio7",
      section: "Categories Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio17",
      section: "SubCategories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio17",
      section: "SubCategories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio8",
      section: "Series Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio9",
      section: "Search Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio10",
      section: "Gallery Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio11",
      section: "Column Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio12",
      section: "Quiz Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio13",
      section: "Question Bank Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio14",
      section: "CMS User Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio15",
      section: "Feedback Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio16",
      section: "Role Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
  ],
  viewer: [
    {
      _id: "oasidguierjio1",
      section: "Users Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio2",
      section: "Comments Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio3",
      section: "Stories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio35",
      section: "Content Publish Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio4",
      section: "Contents Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio18",
      section: "Breaking Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio5",
      section: "Polls Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio6",
      section: "Hashtags Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio7",
      section: "Categories Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio17",
      section: "SubCategories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio8",
      section: "Series Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio9",
      section: "Search Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio10",
      section: "Gallery Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio11",
      section: "Column Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio12",
      section: "Quiz Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio13",
      section: "Question Bank Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio14",
      section: "CMS User Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio15",
      section: "Feedback Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio16",
      section: "Role Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
  ],
  guest: [
    {
      _id: "oasidguierjio1",
      section: "Users Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio2",
      section: "Comments Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio3",
      section: "Stories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio35",
      section: "Content Publish Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio4",
      section: "Contents Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio18",
      section: "Breaking Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio5",
      section: "Polls Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio6",
      section: "Hashtags Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio7",
      section: "Categories Manager",
      list: true,
      view: false,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio17",
      section: "SubCategories Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio8",
      section: "Series Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio9",
      section: "Search Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio10",
      section: "Gallery Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio11",
      section: "Column Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio12",
      section: "Quiz Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio13",
      section: "Question Bank Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio14",
      section: "CMS User Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: false,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio15",
      section: "Feedback Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
    {
      _id: "oasidguierjio16",
      section: "Role Manager",
      list: true,
      view: true,
      add: true,
      edit: true,
      delete: true,
      multiplePublish: true,
      multipleDelete: true,
      publish: true,
    },
  ],
};
