import { createSlice } from "@reduxjs/toolkit";

const initialAppLivesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  checkStatus: "",
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const applivesSlice = createSlice({
  name: "applives",
  initialState: initialAppLivesState,
  reducers: {
    applivesfetch: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    startCall: (state, action) => {
        state.error = null;
        if (action.payload.callType === callTypes.list) {
          state.listLoading = true;
        } else {
          state.actionsLoading = true;
        }
      },
  },
});
