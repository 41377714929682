export default function setupAxios(axios, store) {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
      config.headers.source = "cms";
      config.headers.access_token = process.env.REACT_APP_API_ACCESS_TOKEN;

      return config;
    },
    (err) => Promise.reject(err)
  );
}

