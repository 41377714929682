import { createSlice } from "@reduxjs/toolkit";

const initialPublishesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  publishForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const publishesSlice = createSlice({
  name: "publishes",
  initialState: initialPublishesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getPublishById
    publishFetched: (state, action) => {
      state.actionsLoading = false;
      state.publishForEdit = action.payload.publishForEdit;
      state.error = null;
    },
    // findPublishes
    publishesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createPublish
    publishCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.publish);
    },
    // updatePublish
    publishUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.publish.id) {
          return action.payload.publish;
        }
        return entity;
      });
    },
    publishDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    publishesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    publishRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    publisheRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
    publishBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = true;
    },
    publishesBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = true;
        }
      });
    },
    publishUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = false;
    },
    publishesUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = false;
        }
      });
    },
    publishesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    publishDeletedAndSpammed: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { _id, reason } = action.payload;
      const index = state.entities.findIndex((el) => el._id === _id);
      state.entities[index].deletedReason = reason;
      state.entities[index].isDeleted = true;
      state.entities[index].moderationState = 1;
    },
    publishRemovedFromSpam: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );

      state.entities[index].isDeleted = false;
      state.entities[index].deletedReason = null;
      state.entities[index].moderationState = 2;
    },
    publishesRemovedFromSpam: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
          state.entities[index].deletedReason = null;
          const moderationState = state.entities[index].moderationState;
          state.entities[index].moderationState = moderationState === 0 ? 0 : 2;
        }
      });
    },
    publishRejected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { _id, reason } = action.payload;
      const index = state.entities.findIndex((el) => el._id === _id);
      state.entities[index].rejectedReason = reason;
      state.entities[index].isDeleted = true;
    },
    publishesRejected: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { ids, reason } = action.payload;
      ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
          state.entities[index].rejectedReason = reason;
        }
      });
    },
    publishPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { _id } = action.payload;
      const index = state.entities.findIndex((el) => el._id === _id);
      state.entities[index].isPublished = true;
    },
    publishUnpublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isPublished = true;
    },
    publishesPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { ids } = action.payload;
      ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isPublished = true;
        }
      });
    },
    unpublishesPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { ids } = action.payload;
      ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isPublished = false;
        }
      });
    },
    publishReasonUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { _id, reason } = action.payload;
      const index = state.entities.findIndex((el) => el._id === _id);
      state.entities[index].rejectedReason = reason;
    },
  },
});
