import axios from "axios";

// export const USERS_URL = "api/polls";
export const POLLS_URL = "poll";
export const DROPDOWN_URL = "poll/dropdown";

// READ
export function getAllPolls() {
  return axios.get(POLLS_URL);
}

export function getPollById(pollId) {
  return axios.post(`${POLLS_URL}/getById`, { _id: pollId });
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the poll on the server
export function updatePoll(poll) {
  return axios.put(`${POLLS_URL}/updatePoll`, poll);
}

// UPDATE Status
export function updateStatusForPolls(ids, status) {
  return axios.put(`${POLLS_URL}/updateStatusForPolls`, {
    ids,
    status,
  });
}

export function blockPoll(pollId) {
  return axios.put(`${POLLS_URL}/blockPoll`, { _id: pollId });
}

export function unblockPoll(pollId) {
  return axios.put(`${POLLS_URL}/unblockPoll`, { _id: pollId });
}

export function blockPolls(ids) {
  return axios.put(`${POLLS_URL}/blockPolls`, { _id: ids });
}

export function unblockPolls(ids) {
  return axios.put(`${POLLS_URL}/unblockPolls`, { _id: ids });
}

/*********** */
//  PollList
export function getPolls() {
  return axios.get(`${DROPDOWN_URL}/getPolls`);
}

// CREATE =>  POST: add a new poll to the server
export function createPoll(poll) {
  return axios.post(`${POLLS_URL}/savePoll`, poll);
}

export function findPolls(queryParams) {
  return axios.post(`${POLLS_URL}/getPolls`, queryParams);
}

export function deletePoll(pollId) {
  return axios.put(`${POLLS_URL}/deletePoll`, { _id: pollId });
}

export function deletePolls(ids) {
  return axios.put(`${POLLS_URL}/deletePolls`, { _id: ids });
}

export function publishPoll(pollId) {
  return axios.put(`${POLLS_URL}/publishPoll`, { _id: pollId });
}

export function unpublishPoll(pollId) {
  return axios.put(`${POLLS_URL}/unpublishPoll`, { _id: pollId });
}

export function publishPolls(ids) {
  return axios.put(`${POLLS_URL}/publishPolls`, { _id: ids });
}

export function unpublishPolls(ids) {
  return axios.put(`${POLLS_URL}/unPublishPolls`, { _id: ids });
}

export function restorePoll(pollId) {
  return axios.put(`${POLLS_URL}/restorePolls`, { _id: pollId });
}

export function restorePolls(ids) {
  return axios.put(`${POLLS_URL}/restorePoll`, { _id: ids });
}
