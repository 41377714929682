import axios from "axios";

export const CONTENTS_URL = "contentManager";
export const LOCATION_URL = "location";
const VIDEOS_URL = "videos/dropdown/getVideos";
const SEARCH_URL = "search";

// CREATE =>  POST: add a new content to the server
export function createContent(content) {
  return axios.post(`${CONTENTS_URL}/addContent`, content);
}

// READ
export function getAllContents() {
  return axios.post(`${CONTENTS_URL}/showContent`);
}

export function getAllLocations() {
  return axios.get(`${LOCATION_URL}/dropdown`);
}

// READ
export function getAllVideos() {
  return axios.get(VIDEOS_URL);
}

export function getVideosBySearch(query, source) {
  if (source) {
    return axios.get(
      `${VIDEOS_URL}BySearch/?search=${query}&source=${source.trim()}`
    );
  } else {
    return axios.get(`${VIDEOS_URL}BySearch/?search=${query}`);
  }
}

export function getVideoById(id) {
  return axios.get(`${VIDEOS_URL}ById/?search=${id}`);
}

// UPDATE => PUT: update the content on the server
export function updateContent(content) {
  return axios.put(`${CONTENTS_URL}/updateOne`, content);
}

// UPDATE Status
export function updateStatusForContents(ids, status) {
  return axios.post(`${CONTENTS_URL}/updateStatusForContents`, {
    ids,
    status,
  });
}

export function blockContent(contentId) {
  return axios.put(`${CONTENTS_URL}/blockContent/${contentId}`);
}

export function blockContents(ids) {
  return axios.post(`${CONTENTS_URL}/blockContents`, { ids });
}

export function unblockContent(contentId) {
  return axios.put(`${CONTENTS_URL}/unblockContent/${contentId}`);
}

export function unblockContents(ids) {
  return axios.post(`${CONTENTS_URL}/unblockContents`, { ids });
}

/************ */
export function findContents(queryParams) {
  return axios.post(`${CONTENTS_URL}/showContent`, queryParams);
}

export function findContentsV2(queryParams) {
  return axios.post(`${CONTENTS_URL}/v2/showContent`, queryParams);
}

export function getContentById(contentId) {
  return axios.post(`${CONTENTS_URL}/getContentById`, { _id: contentId });
}

export function hideContent(contentId) {
  return axios.put(`${CONTENTS_URL}/hideContent`, { _id: contentId });
}

export function unhideContent(contentId) {
  return axios.put(`${CONTENTS_URL}/unhideContent`, { _id: contentId });
}

export function hideContents(ids) {
  return axios.put(`${CONTENTS_URL}/hideContents`, { _id: ids });
}

export function unhideContents(ids) {
  return axios.put(`${CONTENTS_URL}/unhideContents`, { _id: ids });
}

export function deleteContent(contentId) {
  return axios.put(`${CONTENTS_URL}/rejectContent`, { _id: contentId });
}

export function deleteContents(ids) {
  return axios.put(`${CONTENTS_URL}/delete`, { _id: ids });
}

export function restoreContent(userId) {
  return axios.put(`${CONTENTS_URL}/restoreContent`, { _id: userId });
}

export function restoreContents(ids) {
  return axios.put(`${CONTENTS_URL}/restore`, { _id: ids });
}

export function setRankContent(queryset) {
  return axios.put(`${CONTENTS_URL}/setRank`, queryset);
}

export function setPublishContent(queryset) {
  return axios.put(`${CONTENTS_URL}/setPublish`, queryset);
}

export function moderateContent(queryset) {
  return axios.post(`${CONTENTS_URL}/moderate`, queryset);
}

export function sendFataakNotification(contentId, customTitle = "") {
  //console.log(contentId);
  return axios.post(`${CONTENTS_URL}/notification/shorts`, {
    contentId,
    customTitle,
  });
}

export function retryContentPushToRegional(contentId) {
  return axios.put(`${CONTENTS_URL}/retryContentPush`, { contentId });
}

export function searchContent({
  query = "",
  pageSize = 20,
  pageNumber = 1,
  source = "",
  manager = "internal",
}) {
  return axios.get(
    `${SEARCH_URL}/content?query=${query}&pageSize=${pageSize}&pageNumber=${pageNumber}&source=${source}&manager=${manager}`
  );
}
