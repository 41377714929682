import { createSlice } from "@reduxjs/toolkit";

const initialCrosswordManagersState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  crossword_users_List: null,
  entities: null,
  anchorForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const crossword_lallantop_theme_managerSlice = createSlice({
  name: "crossword_lallantop_theme_manager",
  initialState: initialCrosswordManagersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAnchorById
    CrosswordManagerFetched: (state, action) => {
      state.actionsLoading = false;
      state.anchorForEdit = action.payload.anchorForEdit;
      state.error = null;
    },
    // findAnchors
    CrosswordManagersFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    //anchorList
    CrosswordManagersListFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.crossword_users_List = entities;
    },
    // createAnchor
    CrosswordManagerCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.anchor);
    },
    // updateAnchor
    CrosswordManagerUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    CrosswordManagerDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
     state.entities[index].isDeleted = true;
    },
    CrosswordManagersDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    CrosswordManagerRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    CrosswordManagersRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
    CrosswordManagerBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = false;
    },
    CrosswordManagersBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = false;
        }
      });
    },
    CrosswordManagerUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = true;
    },
    CrosswordManagersUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = true;
        }
      });
    },
    CrosswordManagersStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
