import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { uiSlice } from "../app/modules/Maintenance/_redux/ui/uiSlice";
import { commonSlice } from "../app/modules/Maintenance/_redux/common/commonSlice";
import { applivesSlice } from "../app/modules/Maintenance/_redux/applives/applivesSlice";
import { usersSlice } from "../app/modules/Maintenance/_redux/users/usersSlice";
import { breakingsSlice } from "../app/modules/Maintenance/_redux/breakings/breakingsSlice";
import { anchorsSlice } from "../app/modules/Maintenance/_redux/anchors/anchorsSlice";
import { commentsSlice } from "../app/modules/Maintenance/_redux/comments/commentsSlice";
import { storiesSlice } from "../app/modules/Maintenance/_redux/stories/storiesSlice";
import { contentsSlice } from "../app/modules/Maintenance/_redux/contents/contentsSlice";
import { externalContentsSlice } from "../app/modules/Maintenance/_redux/external/contentsSlice";
import { publishesSlice } from "../app/modules/Maintenance/_redux/publishes/publishesSlice";
import { pollsSlice } from "../app/modules/Maintenance/_redux/polls/pollsSlice";
// import { hashtagsSlice } from "../app/modules/Maintenance/_redux/hashtags/hashtagsSlice";
import { primaryHashtagsSlice } from "../app/modules/Maintenance/_redux/primaryHashtags/hashtagsSlice";
import { secondaryHashtagsSlice } from "../app/modules/Maintenance/_redux/secondaryHashtags/hashtagsSlice";

import { subCategoriesSlice } from "../app/modules/Maintenance/_redux/subcategories/subCategoriesSlice";
import { categoriesSlice } from "../app/modules/Maintenance/_redux/categories/categoriesSlice";
import { progressionsSlice } from "../app/modules/Maintenance/_redux/progressions/progressionsSlice";
import { livesSlice } from "../app/modules/Maintenance/_redux/lives/livesSlice";
import { searchesSlice } from "../app/modules/Maintenance/_redux/searches/searchesSlice";
import { galleriesSlice } from "../app/modules/Maintenance/_redux/galleries/galleriesSlice";
import { columnsSlice } from "../app/modules/Maintenance/_redux/columns/columnsSlice";
import { quizzesSlice } from "../app/modules/Maintenance/_redux/quizzes/quizzesSlice";
import { twitterSlice } from "../app/modules/Maintenance/_redux/twitter/twitterSlice";
import { adminsSlice } from "../app/modules/Maintenance/_redux/admins/adminsSlice";
import { authorsSlice } from "../app/modules/Maintenance/_redux/authors/authorsSlice";
import { newsapiSlice } from "../app/modules/Maintenance/_redux/newsapi/newsapiSlice";

import { questionsSlice } from "../app/modules/Maintenance/_redux/questions/questionsSlice";
import { feedbacksSlice } from "../app/modules/Maintenance/_redux/feedbacks/feedbacksSlice";
import { rolesSlice } from "../app/modules/Maintenance/_redux/roles/rolesSlice";
import { reportsSlice } from "../app/modules/Maintenance/_redux/reports/reportsSlice";
import { visualstorySlice } from "../app/modules/Maintenance/_redux/visualstory/visualSlice";
import { mediaeSlice } from "../app/modules/Maintenance/_redux/mediae/mediaeSlice";

import { editorialsSlice } from "../app/modules/Maintenance/_redux/editorials/editorialsSlice";
import { sahityasSlice } from "../app/modules/Maintenance/_redux/sahitya/sahityasSlice";
import { lifetakSlice } from "../app/modules/Maintenance/_redux/lifetak/lifetakSlice";
import { creatorsSlice } from "../app/modules/Maintenance/_redux/creators/reportsSlice";
import { mumbaitakSlice } from "../app/modules/Maintenance/_redux/mumbaitak/mumbaitakSlice";
import { uptakSlice } from "../app/modules/Maintenance/_redux/uptak/uptakSlice";
import { uploadSlice } from "../app/modules/Maintenance/_redux/uploads/uploadSlice";
import { adsSlice } from "../app/modules/Maintenance/_redux/ads/adsSlice";

/*commit by manish*/
import { themesSlice } from "../app/modules/Maintenance/_redux/themes/themesSlice";

import { crosswordusersSlice } from "../app/modules/Maintenance/_redux/crossword/crosswordusersSlice";
import { crossword_managerSlice } from "../app/modules/Maintenance/_redux/crossword_manager/crossword_managerSlice";
import { crossword_theme_managerSlice } from "../app/modules/Maintenance/_redux/crossword_theme_manager/crossword_theme_managerSlice";
import { crossword_leaderboardSlice } from "../app/modules/Maintenance/_redux/crossword_leaderboard/crossword_leaderboardSlice";
import { crossword_sportstak_theme_managerSlice } from "../app/modules/Maintenance/_redux/crossword_sportstak_theme_manager/crossword_sportstak_theme_managerSlice";
import { crossword_lallantop_theme_managerSlice } from "../app/modules/Maintenance/_redux/crossword_lallantop_theme_manager/crossword_lallantop_theme_managerSlice";
import { crossword_takapp_theme_managerSlice } from "../app/modules/Maintenance/_redux/crossword_takapp_theme_manager/crossword_takapp_theme_managerSlice";
import { crossword_mumbaitak_theme_managerSlice } from "../app/modules/Maintenance/_redux/crossword_mumbaitak_theme_manager/crossword_mumbaitak_theme_managerSlice";

import { shabdamusersSlice } from "../app/modules/Maintenance/_redux/shabdam/shabdamusersSlice";
import { shabdam_managerSlice } from "../app/modules/Maintenance/_redux/shabdam_word_manager/shabdam_managerSlice";
import { shabdam_gameplayer_managerSlice } from "../app/modules/Maintenance/_redux/shabdam_gameplayer_manager/shabdam_managerSlice";

import { shabdam_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdam_theme_manager/shabdam_theme_managerSlice";
import { shabdam_lallantop_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdam_lallantop_theme_manager/shabdam_lallantop_theme_managerSlice";
import { shabdam_takapp_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdam_takapp_theme_manager/shabdam_takapp_theme_managerSlice";
import { shabdam_mumbaitak_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdam_mumbaitak_theme_manager/shabdam_mumbaitak_theme_managerSlice";
import { shabdam_leaderboardSlice } from "../app/modules/Maintenance/_redux/shabdam_leaderboard/shabdam_leaderboardSlice";

import { shabdjaalusersSlice } from "../app/modules/Maintenance/_redux/shabdjaal/shabdjaalusersSlice";
import { shabdjaal_leaderboardSlice } from "../app/modules/Maintenance/_redux/shabdjaal_leaderboard/shabdjaal_leaderboardSlice";
import { shabdjaal_gameplayer_managerSlice } from "../app/modules/Maintenance/_redux/shabdjaal_gameplayer_manager/shabdjaal_gameplayer_managerSlice";
import { shabdjaal_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdjaal_theme_manager/shabdjaal_theme_managerSlice";
import { shabdjaal_sportstak_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdjaal_sportstak_theme_manager/shabdjaal_sportstak_theme_managerSlice";
import { shabdjaal_lallantop_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdjaal_lallantop_theme_manager/shabdjaal_lallantop_theme_managerSlice";
import { shabdjaal_takapp_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdjaal_takapp_theme_manager/shabdjaal_takapp_theme_managerSlice";
import { shabdjaal_mumbaitak_theme_managerSlice } from "../app/modules/Maintenance/_redux/shabdjaal_mumbaitak_theme_manager/shabdjaal_mumbaitak_theme_managerSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  ui: uiSlice.reducer,
  common: commonSlice.reducer,

  users: usersSlice.reducer,
  anchors: anchorsSlice.reducer,
  comments: commentsSlice.reducer,
  stories: storiesSlice.reducer,
  contents: persistReducer(
    {
      storage,
      key: "v726-demo1-auth1",
      whitelist: ["contentDraft", "uploadedFile"],
    },
    contentsSlice.reducer
  ),
  external: persistReducer(
    { storage, key: "v726-demo2-auth1", whitelist: ["contentDraft"] },
    externalContentsSlice.reducer
  ),
  publishes: publishesSlice.reducer,
  polls: pollsSlice.reducer,
  primaryHashtags: primaryHashtagsSlice.reducer,
  secondaryHashtags: secondaryHashtagsSlice.reducer,
  categories: categoriesSlice.reducer,
  subcategories: subCategoriesSlice.reducer,
  progressions: progressionsSlice.reducer,
  lives: livesSlice.reducer,
  searches: searchesSlice.reducer,
  galleries: galleriesSlice.reducer,
  columns: columnsSlice.reducer,
  quizzes: quizzesSlice.reducer,
  editorials: editorialsSlice.reducer,
  breakings: breakingsSlice.reducer,
  applives: applivesSlice.reducer,
  admins: adminsSlice.reducer,
  authors: authorsSlice.reducer,
  visualstory: visualstorySlice.reducer,
  questions: questionsSlice.reducer,
  feedbacks: feedbacksSlice.reducer,
  // feedbacks: feedbacksSlice.reducer,
  roles: rolesSlice.reducer,
  reports: reportsSlice.reducer,
  ads: adsSlice.reducer,

  mediae: mediaeSlice.reducer,
  sahityas: sahityasSlice.reducer,
  lifetak: lifetakSlice.reducer,
  mumbaitak: mumbaitakSlice.reducer,
  uptak: uptakSlice.reducer,
  creators: creatorsSlice.reducer,
  crossword: crosswordusersSlice.reducer,
  crossword_manager: crossword_managerSlice.reducer,
  crossword_theme_manager: crossword_theme_managerSlice.reducer,
  crossword_leaderboard: crossword_leaderboardSlice.reducer,
  crossword_sportstak_theme_manager:
    crossword_sportstak_theme_managerSlice.reducer,
  crossword_lallantop_theme_manager:
    crossword_lallantop_theme_managerSlice.reducer,
  crossword_takapp_theme_manager: crossword_takapp_theme_managerSlice.reducer,
  crossword_mumbaitak_theme_manager:
    crossword_mumbaitak_theme_managerSlice.reducer,
  uploads: uploadSlice.reducer,

  shabdam: shabdamusersSlice.reducer,
  shabdam_word_manager: shabdam_managerSlice.reducer,
  shabdam_gameplayer_manager: shabdam_gameplayer_managerSlice.reducer,
  shabdam_theme_manager: shabdam_theme_managerSlice.reducer,
  shabdam_lallantop_theme_manager: shabdam_lallantop_theme_managerSlice.reducer,
  shabdam_takapp_theme_manager: shabdam_takapp_theme_managerSlice.reducer,
  shabdam_mumbaitak_theme_manager: shabdam_mumbaitak_theme_managerSlice.reducer,

  shabdjaal: shabdjaalusersSlice.reducer,
  shabdjaal_leaderboard: shabdjaal_leaderboardSlice.reducer,
  shabdjaal_gameplayer_manager: shabdjaal_gameplayer_managerSlice.reducer,
  shabdjaal_theme_manager: shabdjaal_theme_managerSlice.reducer,
  shabdjaal_sportstak_theme_manager:
    shabdjaal_sportstak_theme_managerSlice.reducer,
  shabdjaal_lallantop_theme_manager:
    shabdjaal_lallantop_theme_managerSlice.reducer,
  shabdjaal_takapp_theme_manager: shabdjaal_takapp_theme_managerSlice.reducer,
  shabdjaal_mumbaitak_theme_manager:
    shabdjaal_mumbaitak_theme_managerSlice.reducer,
  shabdam_leaderboard: shabdam_leaderboardSlice.reducer,
  themes: themesSlice.reducer,
  twitter: twitterSlice.reducer,
  newsapi: newsapiSlice.reducer,
  /*commit by manish*/
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
