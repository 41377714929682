import axios from "axios";

// export const USERS_URL = "api/users";
export const USERS_URL = "appUser";

// CREATE =>  POST: add a new user to the server
export function createUser(user) {
  return axios.post(`${USERS_URL}/addUser`, { user });
}

// READ
export function getAllUsers() {
  return axios.get(USERS_URL);
}


//Mark Anchor or not
export function changeAnchorState(queryset){
  return axios.post(`/users/markAsCreator`, {"appUserId":queryset._id, "isCreator":queryset.isCreator,"isVerified":queryset.isVerifiedCreator})
}

export function getUserById(userId) {
  return axios.get(`${USERS_URL}/${userId}`);
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the user on the server
export function updateUser(user) {
  return axios.put(`${USERS_URL}/${user.id}`, { user });
}

// UPDATE Status
export function updateStatusForUsers(ids, status) {
  return axios.post(`${USERS_URL}/updateStatusForUsers`, {
    ids,
    status,
  });
}

/*********** */
export function findUsers(queryParams) {
  return axios.post(`${USERS_URL}/getUsers`, queryParams);
}

export function deleteUser(userId) {
  return axios.put(`${USERS_URL}/delete`, { _id: userId });
}

export function blockUser(userId) {
  return axios.post(`${USERS_URL}/blockUser`, { _id: userId });
}

export function unblockUser(userId) {
  return axios.post(`${USERS_URL}/unblockUser`, { _id: userId });
}

export function deleteUsers(ids) {
  return axios.put(`${USERS_URL}/delete`, { _id: ids });
}

export function blockUsers(ids) {
  return axios.post(`${USERS_URL}/blockUsers`, { _id: ids });
}

export function unblockUsers(ids) {
  return axios.post(`${USERS_URL}/unblockUsers`, { _id: ids });
}

export function restoreUser(userId) {
  return axios.post(`${USERS_URL}/restore`, { _id: userId });
}

export function restoreUsers(ids) {
  return axios.put(`${USERS_URL}/restore`, { _id: ids });
}
