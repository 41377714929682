import { createSlice } from "@reduxjs/toolkit";

const initialAnchorsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  crossword_users_List: null,
  entities: null,
  anchorForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const crosswordusersSlice = createSlice({
  name: "crossword",
  initialState: initialAnchorsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAnchorById
    anchorFetched: (state, action) => {
      state.actionsLoading = false;
      state.anchorForEdit = action.payload.anchorForEdit;
      state.error = null;
    },
    // findAnchors
    crosswordGameUserFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    //anchorList
    crosswordGameUserListFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.crossword_users_List = entities;
    },
    // createAnchor
    anchorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.anchor);
    },
    // updateAnchor
    anchorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    anchorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    anchorsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    anchorRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    anchorsRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
    anchorBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = false;
    },
    anchorsBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = false;
        }
      });
    },
    anchorUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = true;
    },
    anchorsUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = true;
        }
      });
    },
    anchorsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
