import { createSlice } from "@reduxjs/toolkit";

const initialAppLivesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  error: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const adsSlice = createSlice({
  name: "ads",
  initialState: initialAppLivesState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    adsDataCaptured: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    adsUserDatafetch: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
  },
});
