import axios from "axios";

// export const USERS_URL = "api/lives";
// export const LIVES_URL = "admin/v1/live";
export const LIVES_URL = "liveEvent";

// CREATE =>  POST: add a new live to the server
export function createLive(live) {
  return axios.post(`${LIVES_URL}/saveYoutubeLive`, live);
}

// READ
export function getAllLives() {
  return axios.get(LIVES_URL);
}

export function sendNotification(contentId, customTitle = "") {
  return axios.post(`${LIVES_URL}/notification`, {
    contentId,
    customTitle,
  });
}
// export function getLiveById(liveId) {
//   return axios.get(`${LIVES_URL}/${liveId}`);
// }

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the live on the server
export function updateLive(live) {
  // return axios.put(`${LIVES_URL}/${live.id}`, { live });
  return axios.put(`${LIVES_URL}/updateLiveEvents`, live);
}

export function getLiveById(liveId) {
  return axios.post(`${LIVES_URL}/getById`, { _id: liveId });
}

// UPDATE Status
export function updateStatusForLives(ids, status) {
  return axios.post(`${LIVES_URL}/updateStatusForLives`, {
    ids,
    status,
  });
}

export function blockLive(liveId) {
  return axios.post(`${LIVES_URL}/blockLive`, { _id: liveId });
}

export function unblockLive(liveId) {
  return axios.post(`${LIVES_URL}/unblockLive`, { _id: liveId });
}

export function blockLives(ids) {
  return axios.post(`${LIVES_URL}/blockLives`, { _id: ids });
}

export function unblockLives(ids) {
  return axios.post(`${LIVES_URL}/unblockLives`, { _id: ids });
}

export function publishLive(liveId) {
  return axios.put(`${LIVES_URL}/publishLiveEvents`, { _id: [liveId] });
}

/*********** */
export function findLives(queryParams) {
  return axios.post(`${LIVES_URL}/getLiveEvents`, queryParams);
}

export function checkLiveStatus() {
  return axios.get(`${LIVES_URL}/check-status`);
}

export function deleteLive(liveId) {
  return axios.post(`${LIVES_URL}/deleteLive`, { _id: liveId });
}

export function deleteLives(ids) {
  return axios.put(`${LIVES_URL}/deleteLiveEvents`, { _id: ids });
}

export function unpublishLive(liveId) {
  return axios.put(`${LIVES_URL}/unPublishLiveEvents`, { _id: [liveId] });
}

export function publishLives(ids) {
  return axios.put(`${LIVES_URL}/publishLiveEvents`, { _id: ids });
}

export function unpublishLives(ids) {
  return axios.put(`${LIVES_URL}/unPublishLiveEvents`, { _id: ids });
}

export function pauseLive(liveId) {
  return axios.post(`${LIVES_URL}/pauseLive`, { _id: liveId });
}

export function stopLive(liveId) {
  return axios.post(`${LIVES_URL}/stopLive`, { _id: liveId });
}

export function restoreLive(liveId) {
  return axios.post(`${LIVES_URL}/restoreLive`, { _id: liveId });
}

export function restoreLives(ids) {
  return axios.put(`${LIVES_URL}/restoreLiveEvents`, { _id: ids });
}
