import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import * as auth from "./authRedux";
import * as actions from "../../Maintenance/_redux/roles/rolesCrud";
import { getUserByToken } from "./authCrud";

const userRoles = {
  "super": 0,
  "admin": 1,
  "editor": 2,
  "subeditor": 3,
  "externaluser": 4
}

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const resData = await getUserByToken();
          const user = resData.data.result;
          const query =
          {
            "filter": {
              "value": userRoles[resData.data.result.role]
            }
          }
          const rolesData = await actions.findRoles(query);
          const roles = rolesData.data.result;
          dispatch(props.fulfillUser(user, roles));
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined, undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default connect(null, auth.actions)(AuthInit);
