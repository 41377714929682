export default {
  checkField: (value) => (value === null ? "" : value),
  getFieldCSSClasses: (touched, errors) => {
    const classes = ["form-control"];
    if (touched && errors) {
      classes.push("is-invalid");
    }

    // if (touched && !errors) {
    //   classes.push("is-valid");
    // }

    return classes.join(" ");
  },
  extractContent: (html) => {
    return new DOMParser().parseFromString(html, "text/html").documentElement
      .textContent;
  },
};
