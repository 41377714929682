import { createSlice } from "@reduxjs/toolkit";

const initialUIState = {
  imageUrl: null,
  cropRatio: null,
  mediaUrl: null,
  imageState: null,
  imageMidUrl: null,
  imageFinalUrl: null,
  setFieldValue: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const commonSlice = createSlice({
  name: "common",
  initialState: initialUIState,
  reducers: {
    imageUrlUpdated: (state, action) => {
      state.imageUrl = action.payload.imageUrl;
    },
    imageCropRatioUpdated: (state, action) => {
      state.cropRatio = action.payload.cropRatio;
    },
    mediaUrlUpdated: (state, action) => {
      state.mediaUrl = action.payload.mediaUrl;
    },
    imageStateUpdated: (state, action) => {
      state.imageState = action.payload.imageState;
    },
    imageMidUrlUpdated: (state, action) => {
      state.imageMidUrl = action.payload.imageMidUrl;
    },
    imageFinalUrlUpdated: (state, action) => {
      state.imageFinalUrl = action.payload.imageFinalUrl;
    },
    setFieldValueUpdated: (state, action) => {
      state.setFieldValue = action.payload.setFieldValue;
    },
    resetCommon: (state, action) => {
      state.imageUrl = null;
      state.imageFinalUrl = null;
      state.cropRatio = null;
      state.mediaUrl = null;
      state.imageFinalUrl = null;
      state.setFieldValue = null;
      state.imageState = null;
      state.imageMidUrl = null;
    },
  },
});
