import { createSlice } from "@reduxjs/toolkit";

const initialCommentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  commentForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const commentsSlice = createSlice({
  name: "comments",
  initialState: initialCommentsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getCommentById
    commentFetched: (state, action) => {
      state.actionsLoading = false;
      state.commentForEdit = action.payload.commentForEdit;
      state.error = null;
    },
    // findComments
    commentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createComment
    commentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.comment);
    },
    // updateComment
    commentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.comment.id) {
          return action.payload.comment;
        }
        return entity;
      });
    },
    commentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    commentsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    commentBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = true;
    },
    commentsBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = true;
        }
      });
    },
    commentUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = false;
    },
    commentsUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = false;
        }
      });
    },
    commentsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    commentReasonUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { _id, reason } = action.payload;
      const index = state.entities.findIndex((el) => el._id === _id);
      state.entities[index].deletedReason = reason;
    },
    commentDeletedAndSpammed: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const { _id, reason } = action.payload;
      const index = state.entities.findIndex((el) => el._id === _id);
      state.entities[index].deletedReason = reason;
      state.entities[index].isDeleted = true;
      state.entities[index].moderationState = 1;
    },
    commentRemovedFromSpam: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );

      state.entities[index].isDeleted = false;
      state.entities[index].deletedReason = null;
      state.entities[index].moderationState = 2;
    },
    commentsRemovedFromSpam: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
          state.entities[index].deletedReason = null;
          const moderationState = state.entities[index].moderationState;
          state.entities[index].moderationState = moderationState === 0 ? 0 : 2;
        }
      });
    },
  },
});
