import React, { useEffect, useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import fieldHelper from "../../../../app/helpers/fieldHelper";
import dateHelper from "../../../../app/helpers/dateHelper";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  // if (touched && !errors) {
  //   classes.push("is-valid");
  // }

  return classes.join(" ");
};

const dateFormat = (dateStr, isEnd, filterEnd, filterStart = false) => {
  if (dateStr != null) {
    var now = new Date();
    var d = new Date(dateStr);
    var year = d.getFullYear();
    var month = d.getMonth() + 1;
    var day = d.getDate();
    var hour = d.getHours();
    var minute = d.getMinutes();
    if (filterEnd === false) {
      hour = now.getHours();
      minute = now.getMinutes();
    } else if (filterEnd) {
      hour = 23;
      minute = 59;
    }
    if (filterStart) {
      hour = 0;
      minute = 0;
    }
    // var ampm = hour >= 12 ? 'PM' : 'AM';
    //var second  = now.getSeconds();
    if (month.toString().length == 1) {
      month = "0" + month;
    }
    if (day.toString().length == 1) {
      day = "0" + day;
    }
    if (hour.toString().length == 1) {
      hour = "0" + hour;
    }
    if (minute.toString().length == 1) {
      minute = "0" + minute;
    }
    var dateTime = year + "/" + month + "/" + day + " " + hour + ":" + minute;
    return dateTime;
  } else return null;
};

export function DatePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);

  const [date, setDate] = useState("");
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        autoComplete="off"
        dateFormat="dd/MM/yyyy hh:mm aa"
        placeholderText="DD/MM/YYYY"
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={
          field?.value ? field.value : ""
          // (field.value && dateHelper.setTimezone(new Date(field.value))) || null
        }
        onChange={(val) => {
          //if (val) val = dateHelper.setTimezone(val);
          const value = fieldHelper.checkField(val);
          var isEnd = false;
          var filterEnd = false;
          let filterStart = false;
          if (props.isEnd === true) {
            isEnd = true;
          }
          if (props.filterEnd === true) {
            filterEnd = true;
          }
          if (props.filterStart === true) {
            filterStart = true;
          }
          var datetime = dateFormat(value, isEnd, filterEnd, filterStart);
          setDate(datetime);
          setFieldValue(field.name, new Date(datetime));
          props.onChange && props.onChange();
        }}
      />
      {errors[field.name] ? (
        <div
          // className="invalid-datepicker-feedback"
          style={{ color: "tomato" }}
        >
          {errors[field.name].toString()}
        </div>
      ) : null}
    </>
  );
}
