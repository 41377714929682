import { createSlice } from "@reduxjs/toolkit";

const initialAuthorsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  authorsList: null,
  entities: null,
  authorForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const authorsSlice = createSlice({
  name: "authors",
  initialState: initialAuthorsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getAuthorById
    authorFetched: (state, action) => {
      state.actionsLoading = false;
      state.authorForEdit = action.payload.result;
      state.error = null;
    },
    // findAuthors
    authorsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    //authorList
    authorsListFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.authorsList = entities;
    },
    // createAuthor
    authorCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateAuthor
    authorUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    authorDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    authorsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    authorRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    authorsRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
    authorBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = false;
    },
    authorsBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = false;
        }
      });
    },
    authorUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = true;
    },
    authorsUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = true;
        }
      });
    },
    authorsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
  },
});
