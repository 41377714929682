import { createSlice } from "@reduxjs/toolkit";

const initialTwitterSlice = {
  listLoading: false,
  actionsLoading: false,
  currentLocation: "",
  totalCount: 0,
  subCategoriesList: null,
  entities: null,
  contents: [],
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const twitterSlice = createSlice({
  name: "twitter",
  initialState: initialTwitterSlice,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    //getAlltwitterTrends
    twitterTrendsFetched: (state, action) => {
      const { keywords, location } = action.payload;
      state.entities = keywords ? keywords : [];
      state.currentLocation = location ? location : "";
      state.error = null;
      state.listLoading = false;
    },
    trendingContentFetched: (state,action) => {
      state.contents = action.payload;
      state.error = null;
      state.listLoading = false
    }
  },
});
