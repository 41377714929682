import React from "react";
import { useState } from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import fieldHelper from "../../../../app/helpers/fieldHelper";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  // if (touched && !errors) {
  //   classes.push("is-valid");
  // }

  return classes.join(" ");
};

export function DateTimePickerField({ ...props }) {
  const { setFieldValue, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const [date, SetDate] = useState("");
  return (
    <>
      {props.label && <label>{props.label}</label>}
      <DatePicker
        autoComplete="off"
        dateFormat="dd/MM/yyyy hh:mm aa"
        placeholderText="DD/MM/YYYY"
        showTimeInput
        // showTimeSelect
        minDate={Date.now()}
        minTime={Date.now()}
        className={getFieldCSSClasses(touched[field.name], errors[field.name])}
        style={{ width: "100%" }}
        {...field}
        {...props}
        selected={
          (field.value && new Date(field.value)) ||
          (props.setCurrentDateTime ? Date.now() : null)
        }
        onChange={(val) => {
          const value = fieldHelper.checkField(val);

          props.name === "expireAt" && SetDate(new Date(val));

          setFieldValue(field.name, value);
          //* set short rank timing in-case of short video if rank exists
          if (props.isShortVideo === true) {
            setFieldValue("shortsRankFrom", value);
            let now = dayjs(value);
            let shortsRankTo = now.add(2, "h").toISOString();
            setFieldValue("shortsRankTo", shortsRankTo);
          }
          props.onChange && props.onChange();
        }}
      />
      {errors[field.name] ? (
        <div
          // className="invalid-datepicker-feedback"
          style={{ color: "tomato" }}
        >
          {errors[field.name].toString()}
        </div>
      ) : null}
    </>
  );
}
