import axios from "axios";

// export const USERS_URL = "api/authors";
export const USER_URL = "users";
export const AUTHORS_URL = "users";
export const DROPDOWN_URL = "dropdown";

//  AuthorList
export function getAuthors() {
  return axios.get(`${DROPDOWN_URL}/getAuthorUsers`);
}

// CREATE =>  POST: add a new author to the server
export function createAuthor(author) {
  return axios.post(`${USER_URL}/addUser`, author);
}

// READ
export function getAllAuthors() {
  return axios.get(AUTHORS_URL);
}

export function getAuthorById(authorId) {
  return axios.post(`${AUTHORS_URL}/getUserById`, { _id: authorId });
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the author on the server
export function updateAuthor(author) {
  return axios.put(`${AUTHORS_URL}/updateUser`, author);
}

// UPDATE Status
export function updateStatusForAuthors(ids, status) {
  return axios.post(`${AUTHORS_URL}/updateStatusForAuthors`, {
    ids,
    status,
  });
}

/*********** */
export function findAuthors(queryParams) {
  return axios.post(`${AUTHORS_URL}/getUsers`, queryParams);
}

export function deleteAuthor(authorId) {
  return axios.put(`${AUTHORS_URL}/delete`, { _id: authorId });
}

export function blockAuthor(authorId) {
  return axios.post(`${AUTHORS_URL}/blockAuthor`, { _id: authorId });
}

export function unblockAuthor(authorId) {
  return axios.post(`${AUTHORS_URL}/unblockAuthor`, { _id: authorId });
}

export function deleteAuthors(ids) {
  return axios.put(`${AUTHORS_URL}/deleteBulk`, { _id: ids });
}

export function blockAuthors(ids) {
  return axios.post(`${AUTHORS_URL}/blockAuthors`, { _id: ids });
}

export function unblockAuthors(ids) {
  return axios.post(`${AUTHORS_URL}/unblockAuthors`, { _id: ids });
}

export function restoreAuthor(authorId) {
  return axios.put(`${AUTHORS_URL}/restore`, { _id: authorId });
}

export function restoreAuthors(ids) {
  return axios.post(`${AUTHORS_URL}/restoreAuthors`, { _id: ids });
}

export function changePasswordAuthor(_id) {
  return axios.put(`${AUTHORS_URL}/changePassword`, _id);
}
