import axios from "axios";

// export const USERS_URL = "api/categories";
export const CATEGORIES_URL = "category";
export const DROPDOWN_URL = "category/dropdown";

//  CategoriesList
export function getCategories() {
  return axios.get(`${DROPDOWN_URL}/getCategories`);
}

// CREATE =>  POST: add a new category to the server
export function createCategory(category) {
  return axios.post(`${CATEGORIES_URL}/saveCategory`, category);
}

// READ
export function getAllCategories() {
  return axios.get(CATEGORIES_URL);
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the category on the server
export function updateCategory(category) {
  return axios.put(`${CATEGORIES_URL}/updateCategory`, category);
}

// UPDATE Status
export function updateStatusForCategories(ids, status) {
  return axios.post(`${CATEGORIES_URL}/updateStatusForCategories`, {
    ids,
    status,
  });
}

export function blockCategory(categoryId) {
  return axios.put(`${CATEGORIES_URL}/blockCategory`, { _id: categoryId });
}

export function unblockCategory(categoryId) {
  return axios.put(`${CATEGORIES_URL}/unblockCategory`, { _id: categoryId });
}

export function blockCategories(ids) {
  return axios.put(`${CATEGORIES_URL}/blockCategories`, { _id: ids });
}

export function unblockCategories(ids) {
  return axios.put(`${CATEGORIES_URL}/unblockCategories`, { _id: ids });
}

/*********** */
export function findCategories(queryParams) {
  return axios.post(`${CATEGORIES_URL}/getCategories`, queryParams);
}

export function getCategoryById(categoryId) {
  return axios.post(`${CATEGORIES_URL}/getContentById`, { _id: categoryId });
}

export function deleteCategory(categoryId) {
  return axios.put(`${CATEGORIES_URL}/deleteCategory`, { _id: categoryId });
}

export function deleteCategories(ids) {
  return axios.put(`${CATEGORIES_URL}/deleteCategories`, { _id: ids });
}

export function publishCategories(categoryId) {
  return axios.put(`${CATEGORIES_URL}/publishCategory`, {
    _id: categoryId,
    isPublished: true,
  });
}

export function unpublishCategory(categoryId) {
  return axios.put(`${CATEGORIES_URL}/unpublishCategory`, { _id: categoryId });
}

export function publishCategory(state) {
  return axios.put(`${CATEGORIES_URL}/publishCategory`, state);
}

export function unpublishCategories(ids) {
  return axios.put(`${CATEGORIES_URL}/publishCategory`, {
    _id: ids,
    isPublished: false,
  });
}

export function restoreCategory(categoryId) {
  return axios.put(`${CATEGORIES_URL}/restoreCategory`, { _id: categoryId });
}

export function restoreCategories(ids) {
  return axios.put(`${CATEGORIES_URL}/restoreCategories`, { _id: ids });
}
