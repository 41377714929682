import { createSlice, current } from "@reduxjs/toolkit";

const initialVisualStoryState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  lastError: null,
  error: null,
  feedPosition: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const visualstorySlice = createSlice({
  name: "visualstory",
  initialState: initialVisualStoryState,
  reducers: {
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType == callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    storyFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    feedPositionUpdated: (state, action) => {
      state.feedPosition = action.payload;
    },

    storyNotificationTitleUpdate: (state, action) => {
      const { notificationCustomTitle, contentId } = action.payload;

      if (contentId) {
        let index =
          state.entities &&
          state.entities.findIndex((el) => el._id === contentId);
        //console.log(index);
        if (index !== -1) {
          let content = current(state.entities[index]);
          let newObj = Object.assign({ selected: false }, content);
          newObj.notificationCustomTitle = notificationCustomTitle
            ? notificationCustomTitle.trim()
            : "";
          //console.log(newObj);
          state.entities[index] = { ...newObj };
        }
      }
    },
  },
});
