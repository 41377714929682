import * as requestFromServer from "./contentsCrud";
import { contentsSlice, callTypes } from "./contentsSlice";
import { toast } from "react-toastify";

const { actions } = contentsSlice;

export const updateResizedImageUrl = (type, data) => (dispatch) => {
  dispatch(actions.setResizedImageUrl({ type, data }));
};

export const fetchContents = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findContentsV2(queryParams)
    .then((response) => {
      // console.log("assaassasassa: ", response.data.result);
      const {
        result: { entities, totalCount },
      } = response.data;
      // console.log("assa: ", entities);
      dispatch(actions.contentsFetched({ totalCount, entities }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find contents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLocations = () => (dispatch) => {
  return requestFromServer
    .getAllLocations()
    .then((res) => {
      //console.log(res.data);
      dispatch(actions.getLocations(res.data));
    })
    .catch((error) => {
      console.log(error);
    });
};

export const fetchContent = (_id) => (dispatch) => {
  if (!_id) {
    return dispatch(actions.contentFetched({ contentForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getContentById(_id)
    .then((response) => {
      // console.log("coontent: ", response.data.result);

      const contentForEdit = response.data.result;
      dispatch(actions.contentFetched({ contentForEdit }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
export const updateContentDraft = (contentDraft) => (dispatch) => {
  return dispatch(actions.contentDraftUpdated({ contentDraft }));
};
export const resetContentDraft = () => (dispatch) => {
  return dispatch(actions.contentDraftReset());
};

export const updateUploadedFileDraft = (uploadedFileDraft) => (dispatch) => {
  return dispatch(actions.uploadedFileDraftUpdated({ uploadedFileDraft }));
};
export const resetUploadedFileDraft = () => (dispatch) => {
  return dispatch(actions.uploadedFileDraftReset());
};

export const fetchAllVideos = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllVideos()
    .then((response) => {
      const { result } = response.data;
      dispatch(actions.videosFetched({ result }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find contents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchVideoById = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getVideoById(id)
    .then((response) => {
      // console.log("assaassasassa: ", response.data.result);
      const { result } = response.data;
      // console.log("assa: ", result);
      dispatch(actions.videoFetched({ result }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find contents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchVideosBySearch = (query, source) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getVideosBySearch(query, source)
    .then((response) => {
      // console.log("videos: ", response.data.result);
      const { result } = response.data;
      // console.log("result videos: ", result);
      dispatch(actions.videosFetched({ result }));
    })
    .catch((error) => {
      error.clientMessage = "Can't find contents";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const blockContent = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockContent(_id)
    .then((response) => {
      dispatch(actions.contentBlocked({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't block content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const blockContents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .blockContents(ids)
    .then(() => {
      dispatch(actions.contentsBlocked({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't block contents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unblockContent = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockContent(_id)
    .then((response) => {
      dispatch(actions.contentUnblocked({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unblockContents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unblockContents(ids)
    .then(() => {
      dispatch(actions.contentsUnblocked({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't unblock contents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createContent = (contentForCreation, setDisabled) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createContent(contentForCreation)
    .then((response) => {
      const { data } = response;
      dispatch(actions.contentCreated({ data: response.data }));
      dispatch(actions.contentDraftReset());
      if (setDisabled) {
        setDisabled(false);
      }
      return data;
    })
    .catch((error) => {
      if (error.response) {
        error.clientMessage = error.response.data.message;
      } else {
        error.clientMessage = "Something is wrong!";
      }
      if (setDisabled) {
        setDisabled(false);
      }
      // "Can't update content. Duplicate/Incorrect Sef Url/Permission Denied";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toast.error(error.clientMessage);
    });
};

export const updateContent = (contentForUpdation, setDisabled) => (
  dispatch
) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateContent(contentForUpdation)
    .then((response) => {
      const { data } = response;
      if (setDisabled) {
        setDisabled(false);
      }
      dispatch(actions.contentUpdated({ data: response.data }));
      return data;
    })
    .catch((error) => {
      if (error.response) {
        error.clientMessage = error.response.data.message;
      } else {
        error.clientMessage = "Something is Wrong!";
      }
      if (setDisabled) {
        setDisabled(false);
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toast.error(error.clientMessage);
    });
};

export const updateContentsStatus = (ids, status) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForContents(ids, status)
    .then(() => {
      dispatch(actions.contentsStatusUpdated({ ids, status }));
    })
    .catch((error) => {
      error.clientMessage = "Can't update contents status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

/********** */
export const hideContent = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .hideContent(_id)
    .then((response) => {
      dispatch(actions.contentHidden({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't hide content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unhideContent = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unhideContent(_id)
    .then((response) => {
      dispatch(actions.contentUnhidden({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't unhide content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const hideContents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .hideContents(ids)
    .then(() => {
      dispatch(actions.contentsHidden({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't hide contents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const unhideContents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .unhideContents(ids)
    .then(() => {
      dispatch(actions.contentsUnhidden({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't unhide contents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteContent = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteContent(_id)
    .then((response) => {
      dispatch(actions.contentDeleted({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteContents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteContents(ids)
    .then(() => {
      dispatch(actions.contentsDeleted({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't delete contents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const restoreContent = (_id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .restoreContent(_id)
    .then((response) => {
      dispatch(actions.contentRestored({ _id }));
    })
    .catch((error) => {
      error.clientMessage = "Can't restore content";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const restoreContents = (ids) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .restoreContents(ids)
    .then(() => {
      dispatch(actions.contentsRestored({ ids }));
    })
    .catch((error) => {
      error.clientMessage = "Can't restore contents";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const setRankContent = (toUpdate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setRankContent(toUpdate)
    .then((response) => {
      // const { data } = response;
      // console.log("ASAAAS", response.data);
      const { data } = response;
      dispatch(actions.contentUpdated({ data: response.data }));
      return data;
      // dispatch(actions.rankContentUpdated({ data: response.data }));
      // return data;
      // dispatch(actions.setRankContent());
      // dispatch(actions.getAllContents(queryset));
    })
    .catch((error) => {
      error.clientMessage = "Can't update content rank";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toast.error(error.clientMessage);
    });
};

export const setPublishContent = (toUpdate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .setPublishContent(toUpdate)
    .then((response) => {
      // const { data } = response;
      // console.log("publish", response.data);
      const { data } = response;
      dispatch(actions.contentUpdated({ data: response.data }));
      return data;
      // dispatch(actions.rankContentUpdated({ data: response.data }));
      // return data;
      // dispatch(actions.setRankContent());
      // dispatch(actions.getAllContents(queryset));
    })
    .catch((error) => {
      error.clientMessage = "Can't update content publish state";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toast.error(error.clientMessage);
    });
};

export const moderateContent = (toUpdate) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .moderateContent(toUpdate)
    .then((response) => {
      // const { data } = response;
      // console.log("publish", response.data);
      const { data } = response;
      //console.log(data);
      //dispatch(actions.contentUpdated({ data: response.data }));
      toast.success(data?.message);
      return data;
      // dispatch(actions.rankContentUpdated({ data: response.data }));
      // return data;
      // dispatch(actions.setRankContent());
      // dispatch(actions.getAllContents(queryset));
    })
    .catch((error) => {
      let msg = "Can't update content moderation state";
      if (error) {
        msg = error?.response?.data?.message;
      }
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      toast.error(msg);
    });
};

export const sendFataakNotification = (contentId, customTitle = "") => (
  dispatch
) => {
  return requestFromServer
    .sendFataakNotification(contentId, customTitle)
    .then((res) => {
      //console.log("res", res);
      toast.success("Notification sent!");
    })
    .catch((err) => {
      console.log(err);
      toast.error("Failed to sent notification");
    });
};

export const retryContentPushToRegional = (
  contentId = "",
  setDisableRetry = null
) => (dispatch) => {
  return requestFromServer
    .retryContentPushToRegional(contentId)
    .then((res) => {
      toast.success("Request sent to retry");
      if (setDisableRetry) {
        setDisableRetry(false);
      }
    })
    .catch((err) => {
      console.log(err);
      toast.error("Failed to sent request");
      if (setDisableRetry) {
        setDisableRetry(false);
      }
    });
};

export const searchContent = (queryData = "", setSearchDisabled = null) => (
  dispatch
) => {
  return requestFromServer
    .searchContent(queryData)
    .then((res) => {
      if (setSearchDisabled) {
        setSearchDisabled(false);
      }
      // console.log(res.data);
      if (res.data && res.data.result) {
        let { totalCount, entities } = res.data.result;
        dispatch(
          actions.contentsFetched({ totalCount: entities.length, entities })
        );
      }
    })
    .catch((err) => {
      console.error(err);
      toast.error("Failed to send request");
      if (setSearchDisabled) {
        setSearchDisabled(false);
      }
    });
};
