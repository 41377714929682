function begin() {
  return setTimezone(new Date(0));
}

function today() {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return setTimezone(new Date(date));
}

function tomorrow() {
  let date = new Date();
  date = addDays(date, 1);
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  return setTimezone(new Date(date));
}

function toUnix(date) {
  return Math.floor(new Date(date).getTime() / 1000.0);
}

function timeToUnix(time) {
  return new Date(`1970-01-01T${time}Z`).getTime() / 1000;
}

function unixToDate(unix) {
  return resetTimezone(new Date(unix * 1000));
}

function addSeconds(date, add) {
  date = new Date(date);
  date.setSeconds(date.getSeconds() + add);
  return date;
}

function addMinutes(date, add) {
  date = new Date(date);
  date.setMinutes(date.getMinutes() + add);
  return date;
}

function addHours(date, add) {
  date = new Date(date);
  date.setHours(date.getHours() + add);
  return date;
}

function addDays(date, add) {
  date = new Date(date);
  date.setDate(date.getDate() + add);
  return date;
}

function addMonths(date, add) {
  date = new Date(date);
  date.setMonth(date.getMonth() + add);
  return date;
}

function addYears(date, add) {
  date = new Date(date);
  date.setFullYear(date.getFullYear() + add);
  return date;
}

function setTimezone(date) {
  return addSeconds(date, 19800);
}

function resetTimezone(date) {
  return addSeconds(date, -19800);
}

export default {
  begin,
  today,
  tomorrow,
  toUnix,
  timeToUnix,
  unixToDate,
  addSeconds,
  addMinutes,
  addHours,
  addDays,
  addMonths,
  addYears,
  setTimezone,
  resetTimezone,
};
