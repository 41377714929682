import axios from "axios";

// export const USERS_URL = "api/progressions";
export const PROGRESSIONS_URL = "admin/v1/series";
export const DROPDOWN_URL = "admin/v1/dropdown";

// READ
export function getAllProgressions() {
  return axios.get(PROGRESSIONS_URL);
}

export function getProgressionById(progressionId) {
  return axios.post(`${PROGRESSIONS_URL}/getContentById`, {
    _id: progressionId,
  });
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the progression on the server
export function updateProgression(progression) {
  return axios.post(`${PROGRESSIONS_URL}/saveSeries`, progression);
}

// UPDATE Status
export function updateStatusForProgressions(ids, status) {
  return axios.post(`${PROGRESSIONS_URL}/updateStatusForProgressions`, {
    ids,
    status,
  });
}

export function blockProgression(progressionId) {
  return axios.post(`${PROGRESSIONS_URL}/blockProgression`, {
    _id: progressionId,
  });
}

export function unblockProgression(progressionId) {
  return axios.post(`${PROGRESSIONS_URL}/unblockProgression`, {
    _id: progressionId,
  });
}

export function blockProgressions(ids) {
  return axios.post(`${PROGRESSIONS_URL}/blockProgressions`, { _id: ids });
}

export function unblockProgressions(ids) {
  return axios.post(`${PROGRESSIONS_URL}/unblockProgressions`, { _id: ids });
}

/*********** */
//  SeriesList
export function getProgressions() {
  return axios.get(`${DROPDOWN_URL}/getSeries`);
}

// CREATE =>  POST: add a new progression to the server
export function createProgression(progression) {
  return axios.post(`${PROGRESSIONS_URL}/saveSeries`, progression);
}

export function findProgressions(queryParams) {
  return axios.post(`${PROGRESSIONS_URL}/getAllSeries`, queryParams);
}

export function deleteProgression(progressionId) {
  return axios.put(`${PROGRESSIONS_URL}/deleteSeries`, {
    _id: progressionId,
  });
}

export function deleteProgressions(ids) {
  return axios.put(`${PROGRESSIONS_URL}/deleteBulkSeries`, { _id: ids });
}

export function hideProgression(progressionId) {
  return axios.put(`${PROGRESSIONS_URL}/hideSeries`, {
    _id: progressionId,
  });
}

export function unhideProgression(progressionId) {
  return axios.put(`${PROGRESSIONS_URL}/unhideSeries`, {
    _id: progressionId,
  });
}

export function hideProgressions(ids) {
  return axios.put(`${PROGRESSIONS_URL}/hideBulkSeries`, { _id: ids });
}

export function unhideProgressions(ids) {
  return axios.put(`${PROGRESSIONS_URL}/unhideBulkSeries`, { _id: ids });
}

export function restoreProgression(progressionId) {
  return axios.put(`${PROGRESSIONS_URL}/restoreSeries`, {
    _id: progressionId,
  });
}

export function restoreProgressions(ids) {
  return axios.put(`${PROGRESSIONS_URL}/restoreBulkSeries`, { _id: ids });
}

export function publishProgression(progressionId) {
  return axios.put(`${PROGRESSIONS_URL}/publishSeries`, {
    _id: progressionId,
  });
}

export function unpublishProgression(progressionId) {
  return axios.put(`${PROGRESSIONS_URL}/unpublishSeries`, {
    _id: progressionId,
  });
}

export function publishProgressions(ids) {
  return axios.put(`${PROGRESSIONS_URL}/publishBulkSeries`, { _id: ids });
}

export function unpublishProgressions(ids) {
  return axios.put(`${PROGRESSIONS_URL}/unpublishBulkSeries`, { _id: ids });
}
