/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
// import { toAbsoluteUrl } from "../../../_helpers";
// import { AsideSearch } from "./AsideSearch";
import { AsideMenu } from "./aside-menu/AsideMenu";
// import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
// import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
// import { KTUtil } from "./../../../_assets/js/components/util";

export function Aside() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId1: "kt_aside_tab_1",
    tabId2: "kt_aside_tab_2",
  };
  const [activeTab] = useState(tabs.tabId2);
  // const handleTabChange = (id) => {
  //   setActiveTab(id);
  //   const asideWorkspace = KTUtil.find(
  //     document.getElementById("kt_aside"),
  //     ".aside-secondary .aside-workspace"
  //   );
  //   if (asideWorkspace) {
  //     KTUtil.scrollUpdate(asideWorkspace);
  //   }
  // };

  return (
    <>
      {/* begin::Aside */}
      <div
        id="kt_aside"
        className={`aside aside-left d-flex flex-column ${layoutProps.asideClassesFromConfig}`}
      >
        <Brand />

        {/* begin::Aside Toggle */}
        {layoutProps.asideSecondaryDisplay &&
          layoutProps.asideSelfMinimizeToggle && (
            <>
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip id="toggle-aside">Toggle Aside</Tooltip>}
              >
                <span
                  className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                  id="kt_aside_toggle"
                >
                  <i className="ki ki-bold-arrow-back icon-sm" />
                </span>
              </OverlayTrigger>
            </>
          )}
        {/* end::Aside Toggle */}

        {layoutProps.asideSecondaryDisplay && (
          <>
            {/* begin::Secondary */}
            <div
              className="aside-secondary d-flex flex-row-fluid"
              style={{ scrollPadding: "10px" }}
            >
              {/* begin::Workspace */}
              <div className="aside-workspace scroll scroll-push">
                <div className="tab-content">
                  {/* <AsideSearch isActive={activeTab === tabs.tabId1} /> */}
                  <AsideMenu isActive={activeTab === tabs.tabId2} />
                </div>
              </div>
              {/* end::Workspace */}
            </div>
            {/* end::Secondary */}
          </>
        )}
      </div>
      {/* end::Aside */}
    </>
  );
}
