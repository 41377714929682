import { createSlice, current } from "@reduxjs/toolkit";
//import fieldHelper from "../../../../helpers/fieldHelper";
const initialContentsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  contentForEdit: undefined,
  lastError: null,
  error: null,
  errorMessage: null,
  contentDraft: null,
  resizedImages: null,
  locations: null,
  uploadedFile: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const contentsSlice = createSlice({
  name: "contents",
  initialState: initialContentsState,
  reducers: {
    getLocations: (state, action) => {
      state.locations = action.payload;
    },
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.errorMessage = action.payload.error.clientMessage;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    setResizedImageUrl: (state, action) => {
      const { type, data } = action.payload;
      state.resizedImages = { ...state.resizedImages, [type]: data };
    },

    contentNotificationTitleUpdate: (state, action) => {
      const { notificationCustomTitle, contentId } = action.payload;

      if (contentId) {
        let index =
          state.entities &&
          state.entities.findIndex((el) => el._id === contentId);
        //console.log(index);
        if (index !== -1) {
          let content = current(state.entities[index]);
          let newObj = Object.assign({ selected: false }, content);
          newObj.notificationCustomTitle = notificationCustomTitle
            ? notificationCustomTitle.trim()
            : "";
          //console.log(newObj);
          state.entities[index] = { ...newObj };
        }
      }
    },

    // getContentById
    contentFetched: (state, action) => {
      // const { totalCount, entities }
      const { contentForEdit } = action.payload;
      // state.actionsLoading = false;
      // state.error = null;
      // state.contentForEdit = contentForEdit;
      // state.entities = entities;
      // state.totalCount = totalCount;
      let obj = {
        ...contentForEdit,
      };
      if (
        obj.source === "app" &&
        obj.articleHTML !== undefined &&
        obj.articleHTML.length === 0
      ) {
        obj.articleHTML = obj.metaDescription;
      }
      state.actionsLoading = false;
      state.contentForEdit = obj;
      state.error = null;
    },

    // findContents
    contentsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    videoFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.video = result;
    },
    videosFetched: (state, action) => {
      const { result } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.videos = result;
    },
    // createContent
    contentCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateContent
    contentUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // state.entities = state.entities.map((entity) => {
      //   if (entity.id === action.payload.content.id) {
      //     return action.payload.content;
      //   }
      //   return entity;
      // });
    },
    contentDraftUpdated: (state, action) => {
      const { contentDraft } = action.payload;
      state.contentDraft = contentDraft;
    },
    contentDraftReset: (state, action) => {
      state.contentDraft = null;
      state.resizedImages = null;
    },
    uploadedFileDraftUpdated: (state, action) => {
      const { uploadedFileDraft } = action.payload;
      state.uploadedFile = uploadedFileDraft;
    },
    uploadedFileDraftReset: (state, action) => {
      state.uploadedFile = null;
    },
    contentBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = true;
    },
    contentsBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = true;
        }
      });
    },
    contentUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = false;
    },
    contentsUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = false;
        }
      });
    },
    contentsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    contentHidden: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = false;
    },
    contentUnhidden: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isActive = true;
    },
    contentsHidden: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = false;
        }
      });
    },
    contentsUnhidden: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isActive = true;
        }
      });
    },
    contentDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    contentsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    contentRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    contentsRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
  },
});
