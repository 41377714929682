import axios from "axios";

export const STORIES_URL = "admin/v1/story";
export const DROPDOWN_URL = "admin/v1/dropdown";

// READ
export function getAllStories() {
  return axios.get(STORIES_URL);
}

export function getStoryById(storyId) {
  return axios.get(`${STORIES_URL}/${storyId}`);
}

// UPDATE => PUT: update the story on the server
export function updateStory(story) {
  return axios.put(`${STORIES_URL}/${story.id}`, { story });
}

// UPDATE Status
export function updateStatusForStories(ids, status) {
  return axios.post(`${STORIES_URL}/updateStatusForStories`, {
    ids,
    status,
  });
}

export function blockStory(storyId) {
  return axios.put(`${STORIES_URL}/blockStory/${storyId}`);
}

export function blockStories(ids) {
  return axios.post(`${STORIES_URL}/blockStories`, { ids });
}

export function unblockStory(storyId) {
  return axios.put(`${STORIES_URL}/unblockStory/${storyId}`);
}

export function unblockStories(ids) {
  return axios.post(`${STORIES_URL}/unblockStories`, { ids });
}

/**************************** */
//  StoriesList
export function getStories() {
  return axios.get(`${DROPDOWN_URL}/getStories`);
}

// CREATE =>  POST: add a new story to the server
export function createStory(story) {
  return axios.post(`${STORIES_URL}/saveStory`, story);
}

export function findStories(queryParams) {
  return axios.post(`${STORIES_URL}/getStories`, queryParams);
}

export function publishStory(storyId) {
  return axios.put(`${STORIES_URL}/publishStory`, { _id: storyId });
}

export function unpublishStory(storyId) {
  return axios.put(`${STORIES_URL}/unpublishStory`, { _id: storyId });
}

export function publishStories(ids) {
  return axios.put(`${STORIES_URL}/publishStories`, { _id: ids });
}

export function unpublishStories(ids) {
  return axios.put(`${STORIES_URL}/unpublishStories`, { _id: ids });
}

export function deleteStory(storyId) {
  return axios.put(`${STORIES_URL}/deleteStory`, { _id: storyId });
}

export function deleteStories(ids) {
  return axios.put(`${STORIES_URL}/deleteStories`, { _id: ids });
}

export function restoreStory(userId) {
  return axios.put(`${STORIES_URL}/restoreStory`, { _id: userId });
}

export function restoreStories(ids) {
  return axios.put(`${STORIES_URL}/restoreStories`, { _id: ids });
}
