export default [
  {
    _id: "60e41e5fc1abad40b0d67f3e",
    userId: "Sonni",
    userName: "sgabotti0",
    fullname: "Sonni sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "07/07/2021",
    isActive: false,
    isDeleted: true,
    _createdDate: "07/01/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f3f",
    userId: "Abie",
    userName: "sgabotti0",
    fullname: "Abie sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "07/02/2021",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f40",
    userId: "Melody",
    userName: "sgabotti0",
    fullname: "Melody sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "07/03/2021",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f41",
    userId: "Naomi",
    userName: "sgabotti0",
    fullname: "Naomi sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "07/04/2021",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f42",
    userId: "Ashley",
    userName: "sgabotti0",
    fullname: "Ashley sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "07/05/2021",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f43",
    userId: "Mildrid",
    userName: "sgabotti0",
    fullname: "Mildrid sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "9996895534",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "07/06/2021",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f45",
    userId: "Dall",
    userName: "sgabotti0",
    fullname: "Dall sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "IOS",
    lastLoginAt: "07/07/2021",
    isActive: false,
    isDeleted: true,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f46",
    userId: "Burton",
    userName: "sgabotti0",
    fullname: "Burton sgabotti",
    email: "sgabotti0@wsj.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "1234-5678-910",
    deviceType: "Mobile",
    lastLoginAt: "06/30/2019",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
  {
    _id: "60e41e5fc1abad40b0d67f47",
    userId: "Wolf",
    userName: "sgabotti0",
    fullname: "Wolf sgabotti",
    email: "shoaib@domain.com",
    phoneNumber: "345324567865",
    loginType: "gmail",
    deviceId: "uy4-3487dfgi-dfhtr",
    deviceType: "Mobile",
    lastLoginAt: "06/29/2021",
    isActive: false,
    isDeleted: false,
    _createdDate: "03/14/2021",
    _updatedDate: "04/31/2021",
  },
];
