import { createSlice } from "@reduxjs/toolkit";

const initialUIState = {
  queryParams: {
    isDeleted: false,
  },
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState: initialUIState,
  reducers: {
    queryParamsUpdated: (state, action) => {
      const { queryParams } = action.payload;
      state.queryParams = queryParams;
    },
    trashToggoled: (state, action) => {
      const { isDeleted } = action.payload;
      state.queryParams.isDeleted = isDeleted;
    },
    languageToggoled: (state, action) => {
      const { lang_id } = action.payload;
      state.queryParams.lang_id = lang_id;
    },
    
  },
});
