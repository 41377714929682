import { createSlice } from "@reduxjs/toolkit";

const initialStoriesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  storiesList: null,
  storyForEdit: undefined,
  lastError: null,
};
export const callTypes = {
  list: "list",
  action: "action",
};

export const storiesSlice = createSlice({
  name: "stories",
  initialState: initialStoriesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getStoryById
    storyFetched: (state, action) => {
      state.actionsLoading = false;
      state.storyForEdit = action.payload.storyForEdit;
      state.error = null;
    },
    // findStories
    storiesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // findCategoriesList
    storiesListFetched: (state, action) => {
      const { entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.storiesList = entities;
    },
    // createStory
    storyCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      // state.entities.push(action.payload.story);
    },
    // updateStory
    storyUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.story.id) {
          return action.payload.story;
        }
        return entity;
      });
    },
    storyDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = true;
    },
    storiesDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = true;
        }
      });
    },
    storyRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isDeleted = false;
    },
    storiesRestored: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isDeleted = false;
        }
      });
    },
    storyBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = true;
    },
    storiesBlocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = true;
        }
      });
    },
    storyUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].blocked = false;
    },
    storiesUnblocked: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].blocked = false;
        }
      });
    },
    storiesStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map((entity) => {
        if (ids.findIndex((id) => id === entity.id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    },
    storyPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isPublished = true;
    },
    storyUnpublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      const index = state.entities.findIndex(
        (el) => el._id === action.payload._id
      );
      state.entities[index].isPublished = false;
    },
    storiesPublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isPublished = true;
        }
      });
    },
    storiesUpublished: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      action.payload.ids.forEach((_id) => {
        const index = state.entities.findIndex((el) => el._id === _id);
        if (index > -1) {
          state.entities[index].isPublished = false;
        }
      });
    },
  },
});
