import axios from "axios";
import * as actions from "../../Maintenance/_redux/roles/rolesCrud";

// export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
// export const REGISTER_URL = "api/auth/register";
// export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
// export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export const LOGIN_URL = "auth";
export const REGISTER_URL = "users/addUser";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = "/auth/me";
export const ROLES_URL = "permissions";

export function login(email, password) {
  return axios.post(`${LOGIN_URL}/login`, {
    email,
    password
  });
}
export function findRoles(queryParams) {
  return axios.post(`${ROLES_URL}/getRole`, queryParams);
}

export function loginByToken() {
  return axios.get(`${LOGIN_URL}/loginByToken`);
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor
  return loginByToken();
  // return axios.get(ME_URL);
}
