/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
// import SVG from "react-inlinesvg";
import { checkIsActive } from "../../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ isToggled, layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const { state } = useSelector((state) => ({ state }), shallowEqual);
  // console.log(state)
  const entities = state.auth.roles?.entities;
  const { role, categoryArr } = state.auth.user;
  // entities: [
  //   {
  //     section: 'Anchor Manager',
  //     list: true,
  //     add: true,
  //     edit: true,
  //     'delete': true,
  //     restore: true
  //   },
  const anchorRole = entities?.find((x) => x.section === "Anchor Manager");
  const pollRole = entities?.find((x) => x.section === "Polls Manager");
  const contentRole = entities?.find((x) => x.section === "Content Manager");
  const TwitterRole = entities?.find((x) => x.section === "Twitter Manager");
  const NewsAPIRole = entities?.find((x) => x.section === "NewsAPI Manager");
  const subCatRole = entities?.find(
    (x) => x.section === "Sub-Category Manager"
  );
  const AppUploadRole = entities?.find(
    (x) => x.section === "App Upload Manager"
  );
  const AppUserRole = entities?.find((x) => x.section === "App User Manager");
  const catRole = entities?.find((x) => x.section === "Category Manager");
  const reportRole = entities?.find((x) => x.section === "Report Manager");
  const liveRole = entities?.find((x) => x.section === "Live Manager");
  const appliveRole = entities?.find((x) => x.section === "App Live Manager");
  const videoUploadRole = entities?.find(
    (x) => x.section === "Video Upload Manager"
  );
  const creatorRole = entities?.find((x) => x.section === "Creator Manager");
  const hashRole = entities?.find((x) => x.section === "Hashtag Manager");
  const primaryHashTagRole = entities?.find(
    (x) => x.section === "Primary Hashtag Manager"
  );
  const secondaryHashTagRole = entities?.find(
    (x) => x.section === "Secondary Hashtag Manager"
  );
  const editorialRole = entities?.find(
    (x) => x.section === "Editorial Manager"
  );
  const breakingRole = entities?.find((x) => x.section === "Breaking Manager");
  const googleIndexRole = entities?.find((x) => x.section === "Google Index Manager");
  const adsDataRole = entities?.find((x) => x.section === "Live Stream Ads Data Manager");
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

        {/* Admin Panel */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text ">Admin Panel</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Maintenance */}
        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/manage",
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/manage">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
            </span>
            <span className="menu-text">Maintenance</span>
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Maintenance</span>
                </span>
              </li> */}
        {/* end::2 Level*/}
        {/*begin::2 Level*/}
        {contentRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/contents")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/contents">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Contents Manager</span>
            </NavLink>
          </li>
        )}
        {contentRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/external")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/external">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">External Content Manager</span>
            </NavLink>
          </li>
        )}
        {contentRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/visualstory")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/visualstory">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Visual Story Manager</span>
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}
        {/*begin::2 Level*/}
        {role === "super" && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/authors")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/authors">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">CMS Users Manager</span>
            </NavLink>
          </li>
        )}

        {anchorRole.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/anchors")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/anchors">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Anchors Manager</span>
            </NavLink>
          </li>
        )}

        {/* end::2 Level*/}
        {/*begin::2 Level*/}
        {/*end::2 Level */}
        {/*begin::2 Level*/}
        {role === "super" && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/roles")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/roles">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Roles Manager</span>
            </NavLink>
          </li>
        )}
        {liveRole.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/lives")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/lives">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Live Streams Manager</span>
            </NavLink>
          </li>
        )}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/comments")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/comments">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Comments Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/stories")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/stories">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Stories Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {primaryHashTagRole.list && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/primaryHashtags"
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/primaryHashtags">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Primary Hashtags</span>
            </NavLink>
          </li>
        )}

        {secondaryHashTagRole.list && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/secondaryHashtags"
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/secondaryHashtags">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Secondary Hashtags</span>
            </NavLink>
          </li>
        )}

        {/*begin::2 Level*/}
        {catRole.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/categories")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/categories">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Categories Manager</span>
            </NavLink>
          </li>
        )}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {subCatRole.list && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/manage/subcategories"
            )}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/subcategories">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Sub Categories Manager</span>
            </NavLink>
          </li>
        )}

        {reportRole.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/reports")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/reports">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Reports Manager</span>
            </NavLink>
          </li>
        )}

        {AppUploadRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/app")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/app">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">App Upload Manager</span>
            </NavLink>
          </li>
        )}
        {/*end::1 Level*/}
        {videoUploadRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/video")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/video">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Video Upload Manager</span>
            </NavLink>
          </li>
        )}
        {/* end::2 Level*/}
        {/*begin::2 Level*/}
        {AppUserRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/users")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/users">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">App Users Manager</span>
            </NavLink>
          </li>
        )}
        {appliveRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/tak-lives")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/tak-lives">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">App Live Manager</span>
            </NavLink>
          </li>
        )}
        {creatorRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/creators")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/creators">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Creators Manager</span>
            </NavLink>
          </li>
        )}

        {NewsAPIRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/newsapi")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/newsapi">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">NewsAPI Trends</span>
            </NavLink>
          </li>
        )}

        {TwitterRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/twitter")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/twitter">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Twitter Trends</span>
            </NavLink>
          </li>
        )}

        {/*begin::1 Level*/}
        {editorialRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/editorials")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/editorials">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Editorials Manager</span>
            </NavLink>
          </li>
        )}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {breakingRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/breakings")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/breakings">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Breakings Manager</span>
            </NavLink>
          </li>
        )}

        {/*end::2 Level*/}

        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* {contentRole.list &&
          <li
            className={`menu-item ${getMenuItemActive("/manage/publishes")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/publishes">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Content Publish Manager</span>
            </NavLink>
          </li>
        } */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/*end::2 Level*/}

        {/*begin::1 Level*/}

        {/*begin::2 Level*/}
        {pollRole.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/polls")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/polls">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Polls Manager</span>
            </NavLink>
          </li>
        )}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}

        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive(
            "/manage/progressions"
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/progressions">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Series Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/searches")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/searches">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Search Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/galleries")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/galleries">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Gallery Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/columns")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/columns">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Column Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/quizzes")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/quizzes">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Quiz Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level*/}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/questions")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/questions">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Question Bank Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level */}
        {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/manage/feedbacks")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/manage/feedbacks">
            <i className="menu-bullet menu-bullet-dot">
              <span />
            </i>
            <span className="menu-text ">Feedback Manager</span>
          </NavLink>
        </li> */}
        {/*end::2 Level
         {/*begin::2 Level*/}

        {catRole.list &&
          (categoryArr.includes("61ff6f8e819874e296211a9f") ||
            categoryArr.includes("62a6f9eafdc5caf53558f511") ||
            role === "super") && (
            <li
              className={`menu-item ${getMenuItemActive("/manage/sahityas")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/manage/sahityas">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text ">Sahityas Manager</span>
              </NavLink>
            </li>
          )}
        {catRole.list &&
          (categoryArr.includes("61ff6f8e819874e296211a80") ||
            categoryArr.includes("62a6f9eafdc5caf53558f4f2") ||
            role === "super") && (
            <li
              className={`menu-item ${getMenuItemActive("/manage/lifetak")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/manage/lifetak">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text ">LifeTak Manager</span>
              </NavLink>
            </li>
          )}
        {catRole.list &&
          (categoryArr.includes("62a6f9eafdc5caf53558f4f7") ||
            categoryArr.includes("61ff6f8e819874e296211a85") ||
            role === "super") && (
            <li
              className={`menu-item ${getMenuItemActive("/manage/mumbaitak")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/manage/mumbaitak">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text">MumbaiTak Manager</span>
              </NavLink>
            </li>
          )}
        {catRole.list &&
          (categoryArr.includes("61ff6f8e819874e296211a8b") ||
            categoryArr.includes("62a6f9eafdc5caf53558f4fd") ||
            role === "super") && (
            <li
              className={`menu-item ${getMenuItemActive("/manage/uptak")}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/manage/uptak">
                <i className="menu-bullet menu-bullet-dot">
                  <span />
                </i>
                <span className="menu-text ">UPTak Manager</span>
              </NavLink>
            </li>
          )}
        {/*end::2 Level */}
        {/*begin::2 Level*/}

        {(role === "super" || role === "admin") && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/game")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/game">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Game Manager</span>
            </NavLink>
          </li>
        )}
        {googleIndexRole.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/googleIndex")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/googleIndex">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Google Index Manager</span>
            </NavLink>
          </li>
        )}
        {adsDataRole?.list && (
          <li
            className={`menu-item ${getMenuItemActive("/manage/ads")}`}
            aria-haspopup="true"
          >
            <NavLink className="menu-link" to="/manage/ads">
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
              <span className="menu-text ">Live Stream Ads Data Manager</span>
            </NavLink>
          </li>
        )}
        {/* Live Stream Ads Data Manager */}
        {/*end::2 Level
 
        {/* </ul>
          </div>
        </li> */}
        {/*end::2 Level
         {/*begin::2 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/user-profile", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/user-profile">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Add-user.svg"
                )}
              />
            </span>
            <span className="menu-text">User Profile</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
