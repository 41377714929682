import axios from "axios";

// export const USERS_URL = "api/roles";
export const ROLES_URL = "permissions";
export const DROPDOWN_URL = "admin/v1/dropdown";

//  RoleList
export function getRoles() {
  return axios.get(`${DROPDOWN_URL}/getRoleUsers`);
}

// CREATE =>  POST: add a new role to the server
export function createRole(role) {
  return axios.post(ROLES_URL, { role });
}

// READ
export function getAllRoles() {
  return axios.get(ROLES_URL);
}

export function getRoleById(roleId) {
  return axios.get(`${ROLES_URL}/${roleId}`);
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the role on the server
export function updateRole(role) {
  return axios.put(`${ROLES_URL}/updateRole`, role);
}

// UPDATE Status
export function updateStatusForRoles(ids, status) {
  return axios.post(`${ROLES_URL}/updateStatusForRoles`, {
    ids,
    status,
  });
}

/*********** */
export function findRoles(queryParams) {
  return axios.post(`${ROLES_URL}/getRole`, queryParams);
}

export function deleteRole(roleId) {
  return axios.post(`${ROLES_URL}/deleteRole`, { _id: roleId });
}

export function blockRole(roleId) {
  return axios.post(`${ROLES_URL}/blockRole`, { _id: roleId });
}

export function unblockRole(roleId) {
  return axios.post(`${ROLES_URL}/unblockRole`, { _id: roleId });
}

export function deleteRoles(ids) {
  return axios.post(`${ROLES_URL}/deleteRoles`, { _id: ids });
}

export function blockRoles(ids) {
  return axios.post(`${ROLES_URL}/blockRoles`, { _id: ids });
}

export function unblockRoles(ids) {
  return axios.post(`${ROLES_URL}/unblockRoles`, { _id: ids });
}

export function restoreRole(roleId) {
  return axios.post(`${ROLES_URL}/restoreRole`, { _id: roleId });
}

export function restoreRoles(ids) {
  return axios.post(`${ROLES_URL}/restoreRoles`, { _id: ids });
}
