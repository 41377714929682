import axios from "axios";

// export const USERS_URL = "api/hashtags";
export const HASHTAGS_URL = "/primaryHashtag";
export const DROPDOWN_URL = "/primaryHashtag/dropdown";
// const url = "http://3.139.64.35/index.php";

//  Linked list
// export function getLinked(hashtagId) {
//   return axios.post(url, { _id: hashtagId });
// }

//  HashtagsList
export function getHashtags() {
  return axios.get(`${DROPDOWN_URL}/showHashTags`);
}

export function getHashtagsDropdown() {
  return axios.get(`${DROPDOWN_URL}/getHashTags`);
}

// CREATE =>  POST: add a new hashtag to the server
export function createHashtag(hashtag) {
  return axios.post(`${HASHTAGS_URL}/saveHashtag`, hashtag);
}

// READ
export function getAllHashtags() {
  return axios.get(HASHTAGS_URL);
}

// Method from server should return QueryResultsModel(entities: any[], totalCount: number)
// entities => filtered/sorted result

// UPDATE => PUT: update the hashtag on the server
export function updateHashtag(hashtag) {
  return axios.put(`${HASHTAGS_URL}/updateHashTag`, hashtag);
}

// UPDATE Status
export function updateStatusForHashtags(ids, status) {
  return axios.put(`${HASHTAGS_URL}/updateStatusForHashtags`, {
    ids,
    status,
  });
}

export function blockHashtag(hashtagId) {
  return axios.put(`${HASHTAGS_URL}/blockHashtag`, { _id: hashtagId });
}

export function unblockHashtag(hashtagId) {
  return axios.put(`${HASHTAGS_URL}/unblockHashtag`, { _id: hashtagId });
}

export function blockHashtags(ids) {
  return axios.put(`${HASHTAGS_URL}/blockHashtags`, { _id: ids });
}

export function unblockHashtags(ids) {
  return axios.put(`${HASHTAGS_URL}/unblockHashtags`, { _id: ids });
}

/*********** */
export function findHashtags(queryParams) {
  return axios.post(`${HASHTAGS_URL}/showHashTags`, queryParams);
}

export function getHashtagById(hashtagId) {
  return axios.get(`${HASHTAGS_URL}/getHashTagById/${hashtagId}`);
}

export function deleteHashtag(hashtagId) {
  return axios.put(`${HASHTAGS_URL}/deleteHashtag`, { _id: hashtagId });
}

export function deleteHashtags(ids) {
  return axios.put(`${HASHTAGS_URL}/deleteHashTags`, { _id: ids });
}

export function publishHashtag(hashtagId) {
  return axios.put(`${HASHTAGS_URL}/publishHashtag`, { _id: hashtagId });
}

export function unpublishHashtag(hashtagId) {
  return axios.put(`${HASHTAGS_URL}/unpublishHashtag`, { _id: hashtagId });
}

export function publishHashtags(ids) {
  return axios.put(`${HASHTAGS_URL}/publishHashtags`, { _id: ids });
}

export function unpublishHashtags(ids) {
  return axios.put(`${HASHTAGS_URL}/unpublishHashtags`, { _id: ids });
}

export function restoreHashtag(hashtagId) {
  return axios.put(`${HASHTAGS_URL}/restoreHashtag`, { _id: hashtagId });
}

export function restoreHashtags(ids) {
  return axios.put(`${HASHTAGS_URL}/restoreHashTags`, { _id: ids });
}
