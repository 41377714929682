import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export function Brand() {
  return (
    <>
      {/* begin::Brand */}
      {/* <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12`}
      > */}
      {/* begin::Logo */}
      <Link
        to="/"
        className="brand-logo"
        style={{ backgroundColor: "#f3f6f9" }}
      >
        <div style={{ width: 80, textAlign: "center", margin: 10 }}>
          <img
            style={{
              //width: 70,
              height: 50,
            }}
            alt="logo"
            src={toAbsoluteUrl("/media/logos/tak_logo.png")}
            // className="max-h-30px"
          />
          <div style={{ fontSize: "small" }}>Tak Cms</div>
        </div>
      </Link>
      {/* end::Logo */}
      {/* </div> */}
      {/* end::Brand */}
    </>
  );
}
